import React from 'react';
import { sendEvent } from '@src/utils/amplitude';
import * as styles from './PlayerSearchItem.module.scss';
import { Avatar } from '@components/atoms';

const PlayerSearchItem = ({ avatar, linkNumType, nickname, steamAccountId }) => {
	return (
		<a href={`https://app.scope.gg/dashboard/${steamAccountId}`} className={styles.container} onClick={() => {
			sendEvent('New Landing / Search / Profile Click');
		}}>
			<Avatar
				size={40}
				src={avatar}
				alt={nickname}
			/>
			<div className={styles.content}>
				<span className={styles.nickname}>{nickname}</span>
			</div>
		</a>
	)
}

export default PlayerSearchItem
