import SteamID from 'steamid';

class SteamIDParser {
	get(input, options) {
		return new Promise(async (resolve, reject) => {
			options = Object.assign({}, this.options, options);

			let steamid;
			if (!input || (typeof input != 'string' && typeof input != 'number')) return reject(new Error('INVALID_INPUT'));

			if (typeof input == 'number') input = input.toString();

			input = input.replace(/\s/g, '');
			if (input.includes('//steamcommunity.com/')) {
				input = this._cleanURL(input);
			}

			if (isNaN(input)) return reject(new Error('INVALID_INPUT'));

			try {
				steamid = new SteamID(input);
			} catch (err) {}

			if (!steamid || !steamid.isValid()) steamid = new SteamID(input);

			if (!steamid.isValid() && options.checkForAccountID) steamid = SteamID.fromIndividualAccountID(input);

			if (!steamid.isValid()) return reject(new Error('INVALID_INPUT'));

			resolve(steamid);
		});
	}

	_cleanURL(url) {
		if (url.substring(url.length - 1) == '/') url = url.substring(0, url.length - 1);

		return url.split('/').pop();
	}
}

export default SteamIDParser;
