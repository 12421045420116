import React from 'react';
import { sendEvent } from '@src/utils/amplitude';
import * as styles from './PlayerSearchRegister.module.scss'
import { useIntl } from 'gatsby-plugin-react-intl';
import { useSelector } from 'react-redux';
import { mixPanelService } from '../../../services/mixPanelService';

const PlayerSearchRegister = () => {
	const account = useSelector((state) => state.authReducer);
	const intl = useIntl();

	return (
		<div className={styles.container}>
			<div className={styles.buttonRegister}>
				{intl.formatMessage({ id: 'landing.search.emptyTitle' })}
				<a className={styles.signInButton} onClick={() => {
					sendEvent('New Landing / Search / Click Auth Button');
					mixPanelService.sendEvent({
						event: 'Landing / Search / Click Sign Up Button',
					}, account?.userData?.steamAccountId || null)
				}} href={`${window.location.protocol}//app.${window.location.hostname}/dashboard`}>{intl.formatMessage({ id: 'landing.search.signIn' })}</a>
			</div>
		</div>
	)
}

export default PlayerSearchRegister;
