import React from 'react';
import * as styles from './Avatar.module.scss';

const Avatar = ({ src, alt, size }) => {
	return (
		<div className={styles.container}>
			<img className={styles.avatar} src={src} alt={`Scope.gg Avatar ${alt}`}/>
		</div>
	)
}

export default Avatar;
