import React from 'react';
import * as styles from './mobileLangSwitcher.module.scss';
import MenuItem from '@components/molecules/MenuItem';
import { changeLocale, useIntl } from 'gatsby-plugin-react-intl';
import { mixPanelService } from '../../../services/mixPanelService';
import { useSelector } from 'react-redux';

const MobileLangSwitcher = () => {
	const account = useSelector((state) => state.authReducer);
	const intl = useIntl();

	return (
		<div className={styles.container}>
			<button
				className={[styles.submenuItem, intl.locale === 'en' ? styles.active : ''].join(' ')}
				onClick={() => {
					mixPanelService.sendEvent(
						{
							event: 'Landing / Change Language',
							properties: {
								Language: 'en',
							},
						},
						account?.userData?.steamAccountId || null
					);
					changeLocale('en');
				}}
			>
				EN
			</button>
			<button
				className={[styles.submenuItem, intl.locale === 'ru' ? styles.active : ''].join(' ')}
				onClick={() => {
					mixPanelService.sendEvent(
						{
							event: 'Landing / Change Language',
							properties: {
								Language: 'ru',
							},
						},
						account?.userData?.steamAccountId || null
					);
					changeLocale('ru');
				}}
			>
				RU
			</button>
			{/*<button
				className={[styles.submenuItem, intl.locale === 'fr' ? styles.active : ''].join(' ')}
				onClick={() => {
					mixPanelService.sendEvent(
						{
							event: 'Landing / Change Language',
							properties: {
								Language: 'fr',
							},
						},
						account?.userData?.steamAccountId || null
					);
					changeLocale('fr');
				}}
			>
				FR
			</button>
			<button
				className={[styles.submenuItem, intl.locale === 'pt' ? styles.active : ''].join(' ')}
				onClick={() => {
					mixPanelService.sendEvent(
						{
							event: 'Landing / Change Language',
							properties: {
								Language: 'pt',
							},
						},
						account?.userData?.steamAccountId || null
					);
					changeLocale('pt');
				}}
			>
				PT
			</button>
			<button
				className={[styles.submenuItem, intl.locale === 'es' ? styles.active : ''].join(' ')}
				onClick={() => {
					mixPanelService.sendEvent(
						{
							event: 'Landing / Change Language',
							properties: {
								Language: 'es',
							},
						},
						account?.userData?.steamAccountId || null
					);
					changeLocale('es');
				}}
			>
				ES
			</button>*/}
		</div>
	);
};

export default MobileLangSwitcher;
