import React, { useEffect, useState } from 'react';
import * as styles from './headerMenuTooltip.module.scss';
import useResponsive from '@src/utils/useResponsive';
import Logo from '@components/molecules/Logo';
import MenuList from '@components/molecules//MenuList';
import MobileMenuList from '@components/molecules//MobileMenuList';
import LangSwitcher from '@components/molecules/LangSwitcher/LangSwitcher';
import MobileLangSwitcher from '@components/molecules/MobileLangSwitcher/MobileLangSwitcher';
import LoginActions from '@components/molecules/LoginActions/LoginActions';
import MobileLoginActions from '@components/molecules/MobileLoginActions/MobileLoginActions';
import SearchPlayerButton from '@components/molecules/SearchPlayerButton';

import ExcorpLogo from '@src/assets/images/landing/Excorp.svg';
import UnionIcon from '@src/assets/images/landing/Union.svg';
import burger from '@src/assets/images/landing/burger.svg';
import crossIcon from '@src/assets/images/landing/cross-icon.svg';
import BannerTech from '../BannerTech';

import PlayerSearch from '@components/molecules/PlayerSearch';

const HeaderMenuTooltip = ({ onSearchPlayer, onClick }) => {
	const [isDesktop, isTablet, isMobile] = useResponsive();
	const [showMobileMenu, setShowMobileMenu] = useState(false);
	const [showHeader, setShowHeader] = useState(false);
	const [showMobilePlayerSearch, setShowMobilePlayerSearch] = useState(false);

	useEffect(() => {
		if (showMobileMenu) {
			document.body.style.overflowY = 'hidden';
		} else {
			document.body.style.overflowY = 'scroll';
		}
	}, [showMobileMenu]);

	useEffect(() => {
		setShowHeader(true);
	}, []);

	return (
		<div className={styles.container}>
			<SearchPlayerButton onClick={onClick} />

			<MobileMenuList />

			<div className={styles.mobileLang}>
				<MobileLangSwitcher />
			</div>

			{/* <MobileLoginActions location="Mobile menu" /> */}
		</div>
	);
};

export default HeaderMenuTooltip;
