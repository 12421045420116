import React, { useState } from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import faqArrow from '@assets/images/faq-arrow.svg';
import { sendEvent } from '../../../utils/amplitude';

const Accordion = ({ id, title, description, values }) => {
	const intl = useIntl();

	const [active, setActive] = useState(false);

	const handleAccordionClick = () => {
		if (!active) {
			sendEvent('Prematch Landing / FAQ / Open Question', { question: id });
		}

		setActive(!active);
	};

	return (
		<div className="accordion-container" onClick={handleAccordionClick}>
			<div className="accordion-wrapper">
				<div className="accordion-title-wrapper">
					<span className="accordion-title">{intl.formatMessage({ id: title })}</span>
				</div>
				<img className={`accordion-wrapper-img ${active ? 'active' : ''}`} src={faqArrow} alt="arrow" />
			</div>
			<div className={`accordion-content ${active ? 'active' : ''}`} aria-expanded={`${active}`}>
				<div className="accordion-content-text">
					{intl.formatMessage({ id: description }, values ? { ...values } : null)}
				</div>
			</div>
		</div>
	);
};

export default Accordion;
