import React, { useState } from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import * as styles from './Input.module.scss';
import useResponsive from '@src/utils/useResponsive';

import SearchIcon from '@src/assets/icons/search.svg';
import SearchMobileIcon from '@src/assets/icons/search-dark.svg';

const Input = ({ result = [], placeholderText = '', focusedText = '', type, onChange, autoFocus }) => {
	const intl = useIntl();
	const [focused, setFocused] = useState(false);
	const [isDesktop, isTablet, isMobile] = useResponsive();

	return (
		<div className={styles.container}>
			<img className={styles.icon} src={SearchIcon} />
			<input
				autoFocus={autoFocus}
				type={type}
				className={styles.input}
				style={Array.isArray(result) ? { borderBottomLeftRadius: 0, borderBottomRightRadius: 0 } : {}}
				onChange={onChange}
				placeholder={focused ? focusedText : placeholderText}
				onFocus={() => setFocused(true)}
				onBlur={() => setFocused(false)}
			/>
		</div>
	);
};

export default Input;
