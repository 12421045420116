import React, { useState } from 'react';
import * as styles from './menuItem.module.scss';

const MenuItem = ({ children, withSubmenu = false, title = '' }) => {
	const [showSub, setShowSub] = useState(false);

	if (!withSubmenu) {
		return <div className={styles.container}>{children}</div>;
	}

	return (
		<div className={styles.container}>
			<button className={styles.toggleDropdown} onClick={() => setShowSub(!showSub)}>
				<span>{title}</span>
				<span className={[styles.dropdownArrow, 'icon icon--dropdown-arrow'].join(' ')} />
			</button>
			<div className={[styles.submenu, showSub ? styles.open : ''].join(' ')}>{children}</div>
		</div>
	);
};

export default MenuItem;
