import React from "react";

const SvgArrow = () => {
  return (
    <svg width="1153" height="834" viewBox="0 0 1153 834" fill="none" xmlns="http://www.w3.org/2000/svg" style={{width: '100%', height: '100%'}}>
      <path d="M532.672 0H745.067L1153 417.842L745.067 834H532.672L878.235 491.976H0V340.339H874.864L532.672 0Z" style={{fill: 'currentcolor'}}/>
    </svg>
  )
}

export default SvgArrow