import React, { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import Input from '@components/atoms/Input/Input';
import * as styles from './PlayerSearch.module.scss';
import { searchService } from '@src/services/searchService';
import PlayerSearchResult from '@components/molecules/PlayerSearchResult';
import { useIntl } from 'gatsby-plugin-react-intl';
import { steamProfileVanityUrlPattern } from '@scope.gg/steam-profiles';
import SteamIDParser from '../../../utils/steam-id-parser';
import { mixPanelService } from '../../../services/mixPanelService';
import { useSelector } from 'react-redux';

const PlayerSearch = ({ autoFocus = false }) => {
	const account = useSelector((state) => state.authReducer);
	const intl = useIntl();
	const [inputValue, setInputValue] = useState('');
	const [profiles, setProfiles] = useState(null);

	const handleChange = (e) => {
		debouncedChange(e.target.value);
	};

	const debouncedChange = debounce((value) => {
		setInputValue(value);
	}, 2000);

	const checkUserDataExistence = async () => {
		if (!inputValue) return;

		try {
			let accountId;
			let players;

			if (steamProfileVanityUrlPattern.test(inputValue)) {
				const response = await searchService.getAccountIdByVanityUrlAsync(inputValue);

				if (response) {
					accountId = response;
				} else {
					throw new Error(response.errorType);
				}
			} else if (/\/dashboard\/([\d]{2,32})/.test(inputValue)) {
				const splittedInputValue = inputValue.split('/');

				if (!splittedInputValue.length) {
					setProfiles([]);
					return;
				}

				const lastValue = splittedInputValue[splittedInputValue.length - 1];

				if (!lastValue) {
					setProfiles([]);
					return;
				}

				accountId = parseInt(lastValue, 10);
			} else if (/^\d+$/.test(Number(inputValue))) {
				const steamIDParser = new SteamIDParser();
				const parsedResponse = await steamIDParser.get(inputValue, { checkForAccountID: true });

				accountId = parsedResponse.accountid;
			}

			if (accountId) {
				const userData = await searchService.getWhoIsItData(accountId);

				setProfiles([
					{
						nickname: userData.accountData.username,
						avatar: userData.accountData.avatarUrl,
						linkNumType: 'shareCode',
						steamAccountId: userData.accountData.steamAccountId,
					},
				]);
			} else {
				players = await searchService.searchUser(inputValue);

				if (players.length) {
					setProfiles(players);
					mixPanelService.sendEvent(
						{
							event: 'Landing / Search / Response',
							properties: {
								results: 'yes',
							},
						},
						account?.userData?.steamAccountId || null
					);
				} else {
					setProfiles([]);
					mixPanelService.sendEvent(
						{
							event: 'Landing / Search / Response',
							properties: {
								results: 'no',
							},
						},
						account?.userData?.steamAccountId || null
					);
				}
			}
		} catch (error) {
			setProfiles([]);
			if (error.message === 'INVALID_INPUT') {
				setProfiles([]);
			} else if (error.message === 'SERVICE_ERROR') {
				setProfiles([]);
			} else if (error.message === 'USER_NOT_EXISTS') {
				setProfiles([]);
			}
		}
	};

	useEffect(() => {
		checkUserDataExistence();
	}, [inputValue]);

	return (
		<div className={styles.container}>
			<Input
				result={profiles}
				type="text"
				autoFocus={autoFocus}
				placeholderText={intl.formatMessage({ id: 'landing.search.placeholder' })}
				focusedText={intl.formatMessage({ id: 'landing.search.focusText' })}
				onChange={handleChange}
			/>
			{profiles !== null ? <PlayerSearchResult players={profiles} /> : null}
		</div>
	);
};

export default PlayerSearch;
