import { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';

function useResponsive() {
	const [isClient, setIsClient] = useState(false);

	let isMobile = useMediaQuery({
		maxWidth: 600,
	});

	let isTablet = useMediaQuery({
		maxWidth: 1024,
	});

	let isDesktop = useMediaQuery({
		minWidth: 1280,
	});

	useEffect(() => {
		if (typeof window !== 'undefined') setIsClient(true);
	}, []);

	isDesktop = isClient ? isDesktop : true;
	isTablet = isClient ? isTablet : false;
	isMobile = isClient ? isMobile : false;

	return [isDesktop, isTablet, isMobile];
}

export default useResponsive;
