import React from 'react';
import LogoImg from '@src/assets/images/landing/Logo.svg';
import MobileLogoImg from '@src/assets/images/landing/MobileLogo.svg';
import * as styles from './logo.module.scss';

const Logo = ({ size = 'big' }) => {
	if (size === 'big') {
		return (
			<a href="/" style={{ display: 'inline-flex' }}>
				<img className={styles.logoImage} src={LogoImg} alt="SCOPE.GG LOGO" />
			</a>
		);
	} else if (size === 'small') {
		return (
			<div style={{ display: 'flex', alignItems: 'center', width: 'max-content' }}>
				<a href="/" alt="SCOPE.GG LOGO">
					<img src={MobileLogoImg} alt="SCOPE.GG LOGO" />
				</a>
			</div>
		);
	}
};

export default Logo;
