import { axiosInstance } from '../utils/api';

class SearchService {
	searchUser(input) {
		return axiosInstance.post('/accounts/search', {
			"searchString": input
		});
	}
	getAccountIdByVanityUrlAsync(vanityUrl) {
		return axiosInstance.post('/steamProfiles/resolveVanityUrl', {
			vanityUrl,
		})
	}
	getWhoIsItData(steamAccountId) {
		return axiosInstance.post('/accounts/whoIsIt', {
			steamAccountId,
		});
	}
}

export const searchService = new SearchService();
