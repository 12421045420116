import React from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';

const CarouselCard = ({ imageUrl, title, description, isAvailableNow, imageAlt }) => {
	const intl = useIntl();

	return (
		<div className="card-container">
			{!isAvailableNow && (
				<div className="card-badge">
					<div className="card-badge-content-wrapper">
						<span className="card-badge-title">{intl.formatMessage({ id: 'prematch.Soon' })}</span>
					</div>
				</div>
			)}
			<img className="card-image" src={imageUrl} alt={imageAlt} />
			<div className="card-title">
				<span className="card-title-text">{intl.formatMessage({ id: title })}</span>
			</div>
			<div className="card-description">{intl.formatMessage({ id: description })}</div>
		</div>
	);
};

export default CarouselCard;
