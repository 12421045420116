import React from 'react';
import * as styles from './PlayerSearchResult.module.scss';
import PlayerSearchItem from '../PlayerSearchItem';
import PlayerSearchRegister from '../PlayerSearchRegister';

const PlayerSearchResult = ({ players }) => {
	return (
		<div className={styles.container}>
			{Array.isArray(players) && players.map((player) => (
				<PlayerSearchItem
					key={player.steamAccountId}
					avatar={player.avatar}
					linkNumType={player.linkNumType}
					nickname={player.nickname}
					steamAccountId={player.steamAccountId}
				/>
			))}
			<PlayerSearchRegister />
		</div>
	);
};

export default PlayerSearchResult;
