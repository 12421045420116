import React, { useState } from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';

import * as styles from './accordionNew.module.scss';
import { sendEvent } from '../../../utils/amplitude';

import faqArrow from '@assets/images/landing/dropdown-arrow-down-small.svg';

const list = [
	{ id: '1', title: 'CS2 Match Tracker' },
	{ id: '2', title: 'Scores and Rank Distribution' },
	{ id: '3', title: 'CS2 Rank Tracker' },
	{ id: '4', title: 'Match History' },
	{ id: '5', title: 'CS2 Performance Tracker' },
	{ id: '6', title: 'CS2 Leaderboard' },
	{ id: '7', title: 'Track Progress' },
	{ id: '8', title: 'CS2 Weapon Stats' },
	{ id: '9', title: 'CS2 Score Tracker' },
	{ id: '10', title: 'CS2 Player Stats' },
	{ id: '11', title: 'CS2 Map Stats' },
	{ id: '12', title: 'CS2 Kill Stats' },
	{ id: '13', title: 'Stat Checker' },
	{ id: '14', title: 'CS2 Team Stats' },
];

const featuresWithoutAccount = [
	{ id: '1', title: 'View general site content and features.' },
	{ id: '2', title: 'Access public match data and leaderboards.' },
	{
		id: '3',
		title:
			'Explore tutorials and guides. To access personalized stats and insights, you’ll need to create an account and link your Steam profile.',
	},
];

const subscriptionPlans = [
	{ id: '1', boldTitle: 'Free Plan: ', title: 'Access to basic stats, public match data, and leaderboards.' },
	{
		id: '2',
		boldTitle: 'Premium Plan: ',
		title: 'Enhanced stats, advanced performance insights, ad-free experience, and priority support.',
	},
	{
		id: '3',
		boldTitle: 'Pro Plan: ',
		title:
			'All Premium benefits plus exclusive tools, detailed analytics, and early access to new features. Visit our pricing page for more details on each plan.',
	},
];

const AccordionNew = ({ id, title, description, values }) => {
	const intl = useIntl();

	const [active, setActive] = useState(false);

	const handleAccordionClick = () => {
		setActive(!active);
	};

	return (
		<div className={styles.container} onClick={handleAccordionClick}>
			<div className={styles.wrapper}>
				<div className={styles.titleWrapper}>
					<span className={styles.title}>{intl.formatMessage({ id: title })}</span>
				</div>
				<img className={[styles.wrapperImg].join(' ')} src={faqArrow} alt="arrow" />
			</div>
			<div className={[styles.content, active ? styles.active : ''].join(' ')} aria-expanded={`${active}`}>
				<div className={styles.contentText}>
					{intl.formatMessage({ id: description }, values ? { ...values } : null)}
				</div>

				{intl.locale === 'en' && id === '1' && (
					<div className={[styles.extendedFirstAnswerWrapper].join(' ')}>
						<div className={[styles.extendedFirstAnswerWrapperTitle].join(' ')}>Key Features and Tools:</div>
						<ul className={[styles.extendedFirstAnswerWrapperTable].join(' ')}>
							{list.map((item) => (
								<li key={item.id} className={[styles.listitems].join(' ')}>
									{item.title}
								</li>
							))}
						</ul>
					</div>
				)}

				{intl.locale === 'en' && id === '10' && (
					<div className={[styles.extendedTenAnswerWrapper].join(' ')}>
						<ul className={[styles.extendedTenAnswerWrapperTable].join(' ')}>
							{featuresWithoutAccount.map((item) => (
								<li key={item.id} className={[styles.listitems].join(' ')}>
									{item.title}
								</li>
							))}
						</ul>
					</div>
				)}

				{intl.locale === 'en' && id === '15' && (
					<div className={[styles.extendedTenAnswerWrapper].join(' ')}>
						<ul className={[styles.extendedTenAnswerWrapperTable].join(' ')}>
							{subscriptionPlans.map((item) => (
								<li key={item.id} className={[styles.listitems].join(' ')}>
									<span className={[styles.listitemsbold].join(' ')}>{item.boldTitle}</span>
									{item.title}
								</li>
							))}
						</ul>
					</div>
				)}
			</div>
		</div>
	);
};

export default AccordionNew;
