import React, { useEffect, useState } from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import * as styles from './searchPlayerButton.module.scss';

import SearchIcon from '@src/assets/icons/search-light.svg';
import SearchMobileIcon from '@src/assets/icons/search-dark.svg';

const SearchPlayerButton = ({ onClick }) => {
	const intl = useIntl();

	return (
		<button className={styles.container} onClick={onClick}>
			<img className={styles.icon} src={SearchIcon} />
			<span className={styles.title}>{intl.formatMessage({ id: 'landing.header.searchPlayer' })}</span>
			<img className={styles.mobileIcon} src={SearchMobileIcon} />
		</button>
	);
};

export default SearchPlayerButton;
