import { isBrowser } from './ssr';

/**
 *
 * @param {Object} event
 */
export const sendDataLayerEvent = (event) => {
	if (isBrowser) {
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push(event);
	}
};
