import React from 'react';
import * as styles from './langSwitcher.module.scss';
import MenuItem from '@components/molecules/MenuItem';
import { changeLocale, useIntl } from 'gatsby-plugin-react-intl';
import { mixPanelService } from '../../../services/mixPanelService';
import { useSelector } from 'react-redux';

const LangSwitcher = () => {
	const account = useSelector(state => state.authReducer);
	const intl = useIntl();

	return (
		<MenuItem withSubmenu title={intl.locale}>
			<button className={styles.submenuItem} onClick={() => {
				mixPanelService.sendEvent({
					event: 'Landing / Change Language',
					properties: {
						Language: 'en',
					}
				}, account?.userData?.steamAccountId || null)
				changeLocale('en')
			}}>
				EN
			</button>
			<button className={styles.submenuItem} onClick={() => {
				mixPanelService.sendEvent({
					event: 'Landing / Change Language',
					properties: {
						Language: 'ru',
					}
				}, account?.userData?.steamAccountId || null)
				changeLocale('ru')
			}}>
				RU
			</button>
			{/*<button className={styles.submenuItem} onClick={() => {
				mixPanelService.sendEvent({
					event: 'Landing / Change Language',
					properties: {
						Language: 'fr',
					}
				}, account?.userData?.steamAccountId || null)
				changeLocale('fr')
			}}>
				FR
			</button>
			<button className={styles.submenuItem} onClick={() => {
				mixPanelService.sendEvent({
					event: 'Landing / Change Language',
					properties: {
						Language: 'pt',
					}
				}, account?.userData?.steamAccountId || null)
				changeLocale('pt')
			}}>
				PT
			</button>
			<button className={styles.submenuItem} onClick={() => {
				mixPanelService.sendEvent({
					event: 'Landing / Change Language',
					properties: {
						Language: 'es',
					}
				}, account?.userData?.steamAccountId || null)
				changeLocale('es')
			}}>
				ES
			</button>*/}
		</MenuItem>
	);
};

export default LangSwitcher;
